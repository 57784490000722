import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { RegistrationMetricsData } from "@/registration-tools/utils/metrics";
import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import {
  PropOptional,
  validateArrayOf,
  validateEnumValue,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import {
  GUID,
  IPose,
  ISOTimeString,
  validatePose,
} from "@faro-lotv/ielement-types";
import {
  ActiveBackgroundTaskOrderedList,
  BackgroundTaskState,
  CadImporterErrorCode,
  PCStreamGeneratorErrorCode,
  ProgressApiSupportedTaskTypes,
  validateRegistrationMetrics,
} from "@faro-lotv/service-wires";

/** Base information shared by all background tasks */
export type BackgroundTaskBase = {
  /** A unique ID of the background task */
  id: GUID;

  /** The date when the task has been created */
  createdAt: ISOTimeString;

  /** The date when the task has been last changed */
  changedAt: ISOTimeString;

  /** ID of the iElement which is being processed */
  iElementId: GUID | null;

  /** Name of the task */
  type: BackgroundTaskTypes;

  /** A message to the developer / user with additional information, e.g. when a state is failed */
  devMessage: string | null;

  /**
   * If the state is failed, this field can contain an error code describing the type of error.
   *
   * See https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3539435793/Error+Codes+Overview.
   *
   * You can use the `BackgroundTaskErrorCode` enum and the `validateEnumValue` type guard to handle known error codes.
   */
  errorCode?: PCStreamGeneratorErrorCode | string;

  /** The current state of the background task */
  state: BackgroundTaskState;

  /** The current progress in percentage */
  progress: number;

  /**
   * Timestamp (ms from epoch so can be compared with See Date.now()) of when this task is expected to end
   *
   * using this value instead of the "remainingTime" as this value will not need to continuously change
   */
  expectedEnd?: number;

  /**
   * If defined to true then this task can be cancelled
   *
   * This is currently tied to the file upload cancellation only.
   * TODO: Generalize the cancellation logic for multiple tasks https://faro01.atlassian.net/browse/SWEB-1350
   */
  canBeCanceled?: boolean;

  /**
   * True if this task should prompt the user before allowing the window to close.
   * Typically used for front end tasks like file upload that will lose progress if the browser closes.
   * Can remain undefined or false for back end tasks where we are tracking progress but will continue
   * on browser close.
   */
  shouldPreventWindowClose?: boolean;

  /** Additional metadata on this task that can be used for reporting (Eg. the file name of an upload task) */
  metadata?: Record<string, string | undefined>;

  /**
   * An unordered optional collection of arbitrary strings a producer might attach
   * to enable clients to implement more granular filtering depending on the use-case.
   */
  tags?: string[];
};

/**
 * List of all the names of client side managed background tasks
 *
 * Using a list instead of an enum to be able to compose this list with other lists
 */
const CLIENT_SIDE_TASK_TYPES = ["FileUpload", "Orthophoto"] as const;

/**
 * List of all the supported background task names.
 *
 * Using a list instead of an enum to be able to compose this list from client tasks and backend tasks
 */
// eslint-disable-next-line unused-imports/no-unused-vars
const BACKGROUND_TASK_TYPES = [
  ...CLIENT_SIDE_TASK_TYPES,
  ...Object.values(ProgressApiSupportedTaskTypes),
] as const;

/** All the possible background task names as a type */
export type BackgroundTaskTypes = (typeof BACKGROUND_TASK_TYPES)[number];

/** A task to track the upload of a file */
export type FileUploadTask = BackgroundTaskBase & {
  type: "FileUpload";

  /** Name and type of the uploaded file */
  metadata: {
    filename: string;
    uploadElementType: UploadElementType;

    /**
     *  Optional parameter to provide the name of the uploaded element as reported to the user.
     * It could be different form the actual name of elementId
     */
    elementName?: string;
  };

  /** Whether progress toast notifications should be hidden */
  silent?: boolean;
};

/**
 * @returns true if the task is a FileUploadTask
 * @param task to check
 */
export function isFileUploadTask(
  task: BackgroundTaskBase,
): task is FileUploadTask {
  return task.type === "FileUpload";
}

/**
 * @returns true if the task is a PointCloudExportTask
 * @param task to check
 */
export function isPointCloudExportTask(
  task: BackgroundTask,
): task is PointCloudExportTask {
  return task.type === ProgressApiSupportedTaskTypes.pointCloudExport;
}

/**
 * A task that track the conversion to Potree in the backend
 *
 * TODO: Remove ProgressApiSupportedTaskTypes.pointCloudLazToPotree after backend update: https://faro01.atlassian.net/browse/SWEB-1980
 */
export type PointCloudProcessingTask = BackgroundTaskBase & {
  type:
    | ProgressApiSupportedTaskTypes.pointCloudLazToPotree
    | ProgressApiSupportedTaskTypes.pointCloudToPotree
    | ProgressApiSupportedTaskTypes.pointCloudE57ToLaz
    | ProgressApiSupportedTaskTypes.pointCloudGSToLaz;
};

/** A task that track the export of a subvolume from a point cloud */
export type PointCloudExportTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.pointCloudExport;

  metadata: {
    /** The id of the job provided by the backend after a request */
    jobId: string;
    /** The url where the point cloud is stored */
    downloadUrl?: string;
  };
};

/**
 * @returns true if the task is a PointCloudProcessingTask
 * @param task to check
 */
export function isPointCloudProcessingTask(
  task: BackgroundTaskBase,
): task is PointCloudProcessingTask {
  switch (task.type) {
    // TODO: Remove after backend update: https://faro01.atlassian.net/browse/SWEB-1980
    case ProgressApiSupportedTaskTypes.pointCloudToPotree:
    case ProgressApiSupportedTaskTypes.pointCloudLazToPotree:
    case ProgressApiSupportedTaskTypes.pointCloudE57ToLaz:
    case ProgressApiSupportedTaskTypes.pointCloudGSToLaz:
      return true;
  }
  return false;
}

/** A task that tracks the pairwise registration (i.e., alignment) of point clouds. */
export type C2CRegistrationTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.c2cRegistration;

  /** If state=="succeeded", this contains the result of a cloud-to-cloud registration */
  result?: C2CRegistrationResult;
};

/**
 * Registration result fields used for calculation
 */
export interface C2CRegistrationResult {
  /**
   * revision of the object
   * == 0: trafo is LHZ,
   * >= 1: trafo is LHY
   */
  jsonRevision: number;

  /** local transformation between the point clouds */
  transformation: IPose;

  /** metrics of the registration */
  metrics: RegistrationMetricsData;
}

/**
 * @returns true if the result is a C2CRegistrationResult
 * @param obj to check
 */
export function validateC2CRegistrationResult(
  obj: unknown,
): obj is C2CRegistrationResult {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  const result: Partial<C2CRegistrationResult> = obj;
  return (
    validatePrimitive(result, "jsonRevision", "number") &&
    validateOfType(result, "transformation", validatePose) &&
    validateOfType(result, "metrics", validateRegistrationMetrics)
  );
}

/**
 * @returns true if the task is a C2CRegistrationTask
 * @param task to check
 */
export function isC2CRegistrationTask(
  task: BackgroundTaskBase,
): task is C2CRegistrationTask {
  return task.type === ProgressApiSupportedTaskTypes.c2cRegistration;
}

/** A task that tracks the registration (i.e., alignment) of a whole dataset. */
export type RegisterMultiCloudDataSetTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.registerMultiCloudDataSet;

  /** If state == "succeeded", this contains the report of the multi cloud registration. */
  result?: MultiRegistrationReport;
};

/**
 * @returns true if the task is a RegisterMultiCloudDataSetTask
 * @param task to check
 */
export function isRegisterMultiCloudDataSetTask(
  task: BackgroundTaskBase,
): task is RegisterMultiCloudDataSetTask {
  return task.type === ProgressApiSupportedTaskTypes.registerMultiCloudDataSet;
}

/** A task that tracks the merging of multiple point clouds into a single dataset. */
export type MergePointCloudsTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.mergePointClouds;
};

/**
 * @returns true if the task is a MergePointCloudsTask
 * @param task to check
 */
export function isMergePointCloudsTask(
  task: BackgroundTaskBase,
): task is MergePointCloudsTask {
  return task.type === ProgressApiSupportedTaskTypes.mergePointClouds;
}

/** Any task which is generated by the registration backend. */
export type GenericRegistrationTask =
  | C2CRegistrationTask
  | RegisterMultiCloudDataSetTask
  | MergePointCloudsTask;

/**
 *
 * @param task to check
 * @returns true if the task is any task generated by the registration backend
 */
export function isGenericRegistrationTask(
  task: BackgroundTaskBase,
): task is GenericRegistrationTask {
  return (
    isC2CRegistrationTask(task) ||
    isRegisterMultiCloudDataSetTask(task) ||
    isMergePointCloudsTask(task)
  );
}

/** A task with result which has been completed successfully. */
// TODO: Extend to other task types as results are added
export type SuccessfullyCompleted<T extends RegisterMultiCloudDataSetTask> =
  T & {
    state: BackgroundTaskState.succeeded;
    result: NonNullable<T["result"]>;
  };

/**
 * @param task The task to validate.
 * @returns `true`, if the task has been successfully completed with a result, else `false`.
 */
export function isSuccessfullyCompletedWithResult<
  T extends RegisterMultiCloudDataSetTask,
>(task: T): task is SuccessfullyCompleted<T> {
  return task.state === BackgroundTaskState.succeeded && !!task.result;
}

/**
 * A task that track the conversion of a Bim/CAD in the backend
 */
export type BimProcessingTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.bimModelImport;
};

/**
 * @returns true if the task is a BimProcessingTask
 * @param task to check
 */
export function isBimProcessingTask(
  task: BackgroundTaskBase,
): task is BimProcessingTask {
  return task.type === ProgressApiSupportedTaskTypes.bimModelImport;
}

/** A task that tracks the processing of VideoMode recordings */
export type VideoModeProcessingTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.videoMode;
};

/**
 * @returns true if the task is a VideoModeProcessingTask
 * @param task to check
 */
export function isVideoModeProcessingTask(
  task: BackgroundTaskBase,
): task is VideoModeProcessingTask {
  return task.type === ProgressApiSupportedTaskTypes.videoMode;
}

export type ProcessingTask =
  | PointCloudProcessingTask
  | BimProcessingTask
  | VideoModeProcessingTask
  | SceneConversionTask;

/**
 * @returns true if the task is a ProcessingTask which could be a PointCloud or Bim processing
 * @param task to check
 */
export function isProcessingTask(
  task: BackgroundTaskBase,
): task is ProcessingTask {
  return (
    isPointCloudProcessingTask(task) ||
    isBimProcessingTask(task) ||
    isVideoModeProcessingTask(task) ||
    isSceneConversionTask(task)
  );
}

type SceneConversionTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.sceneConversion;
};

/**
 * @returns true if the task is a SceneConversionTask
 * @param task to check
 */
export function isSceneConversionTask(
  task: BackgroundTaskBase,
): task is SceneConversionTask {
  return task.type === ProgressApiSupportedTaskTypes.sceneConversion;
}

export type Captur3dFloorplanGenerationResult = {
  /** URI of the floorplan PNG file */
  floorplanPngUrl: string;

  /** URI of the floorplan SVG file */
  floorplanSvgUrl: string;

  /** URI of the floorplan PDF file */
  floorplanPdfUrl: string;

  /** URL of the project */
  projectUrl: string;

  /** List of issues */
  issues: string[] | null;
};

/** Task for capturing the progress of the floorplan generation via the third-party service Captur3d */
export type Captur3dFloorplanGenerationTask = BackgroundTaskBase & {
  type: ProgressApiSupportedTaskTypes.captur3dFloorplanGeneration;

  /** Result of the floorplan generation */
  result?: Captur3dFloorplanGenerationResult;
};

/**
 * @returns true of given object is of type Captur3dFloorplanGenerationResult
 * @param obj Object to test
 */
export function isCaptur3dFloorplanGenerationResult(
  obj: unknown,
): obj is Captur3dFloorplanGenerationResult {
  if (!validateNotNullishObject(obj, "Captur3dFloorplanGenerationResult")) {
    return false;
  }

  const result: Partial<Captur3dFloorplanGenerationResult> = obj;
  return (
    validatePrimitive(result, "floorplanPngUrl", "string") &&
    validatePrimitive(result, "floorplanSvgUrl", "string") &&
    validatePrimitive(result, "floorplanPdfUrl", "string") &&
    validatePrimitive(result, "projectUrl", "string") &&
    validateArrayOf({
      object: result,
      prop: "issues",
      elementGuard: (el) => typeof el === "string",
      optionality: PropOptional,
    })
  );
}

/**
 * @returns true if the task is a SceneConversionTask
 * @param task to check
 */
export function isCaptur3dFloorplanGenerationTask(
  task: BackgroundTaskBase,
): task is Captur3dFloorplanGenerationTask {
  return (
    task.type === ProgressApiSupportedTaskTypes.captur3dFloorplanGeneration &&
    validatePrimitive(task, "task", "object", PropOptional)
  );
}

/** All the supported background tasks */
export type BackgroundTask =
  | FileUploadTask
  | OrthophotoTask
  | PointCloudProcessingTask
  | PointCloudExportTask
  | GenericRegistrationTask
  | BimProcessingTask
  | VideoModeProcessingTask
  | SceneConversionTask
  | Captur3dFloorplanGenerationTask;

/**
 * @returns ordered list of tasks based on the order specified in ActiveBackgroundTaskOrderedList
 * @param tasks List of tasks that needs to be sorted
 * @param orderBy The order in which the list needs to be sorted
 */
export function sortActiveBackgroundTasks(
  tasks: BackgroundTask[],
  orderBy: "asc" | "desc" = "asc",
): BackgroundTask[] {
  return tasks.sort(
    (a, b) =>
      ActiveBackgroundTaskOrderedList.indexOf(
        (orderBy === "asc" ? a : b).state,
      ) -
      ActiveBackgroundTaskOrderedList.indexOf(
        (orderBy === "asc" ? b : a).state,
      ),
  );
}

/**
 * @returns The name of the task and the corresponding error code
 * @param task Task to use
 * @param elementName Name of the element to use, potentially
 */
export function getErrorCodeAndTaskName(
  task: BackgroundTask,
  elementName?: string,
): {
  errorCode?: PCStreamGeneratorErrorCode | CadImporterErrorCode;
  taskName: string;
} {
  // If the error code is known, give a more specific error message
  // Otherwise, use `undefined` as fallback, which will give a generic error message
  const errorCode = validateEnumValue(task.errorCode, {
    ...PCStreamGeneratorErrorCode,
    ...CadImporterErrorCode,
  })
    ? task.errorCode
    : undefined;

  const taskName =
    (isFileUploadTask(task) ? task.metadata.filename : elementName) ??
    task.type;

  return { taskName, errorCode };
}

/**  A task to track the generation and download of an orthophoto */
export type OrthophotoTask = BackgroundTaskBase & {
  type: "Orthophoto";

  metadata: {
    /** The url to download the orthophoto */
    downloadUrl?: string;
  };
};

/**
 * @returns true if the task is an Orthophoto generation task
 * @param task to check
 */
export function isOrthophotoTask(
  task: BackgroundTaskBase,
): task is OrthophotoTask {
  return task.type === "Orthophoto";
}
