import { getCoordinateStringZUp } from "@/components/ui/scene-context-menu";
import {
  FaroPopover,
  FaroText,
  neutral,
  PointCloudSmallIcon,
  WorldCoordinateIcon,
} from "@faro-lotv/flat-ui";
import {
  convertToDateString,
  isLoading,
  Loading,
  SignedUrl,
} from "@faro-lotv/foundation";
import { ISOTimeString, URI } from "@faro-lotv/ielement-types";
import { useValidUrl } from "@faro-lotv/project-source";
import {
  CardMedia,
  Fade,
  PopperProps,
  Skeleton,
  useTheme,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useMemo, useState } from "react";
import { Vector3Tuple } from "three";

/** The height of the preview image in px */
const PREVIEW_IMAGE_HEIGHT = 180;

type PlaceholderPreviewBaseProps = {
  /** The name of the placeholder. */
  name?: string;

  /** The URL to the image to render. */
  imageUri?: URI | SignedUrl | null | Loading;

  /** ISO 8601 timestamp when the element has been created. */
  createdAt?: ISOTimeString;

  /** Whether the preview should be visible */
  isVisible?: boolean;

  /** The anchor element to attach the preview to. */
  anchorEl?: PopperProps["anchorEl"];

  /** The name of the dataset the placeholder belongs to. */
  datasetName?: string;

  /** The coordinates (y-up) of the placeholder. */
  coordinates?: Vector3Tuple;

  /** Additional modifiers to add to the popper component. */
  popperModifiers?: PopperProps["modifiers"];
};

/**
 * @returns  A dialog that displays a preview of an Img360 placeholder on hover,
 * while handling warm-up and cool-down transitions. The warm-up transition
 * introduces a delay before showing the preview window upon hovering,
 * ensuring a smooth and deliberate interaction. The cool-down transition
 * introduces a delay before hiding the preview window after moving the
 * pointer away, preventing flickering or abrupt disappearance.
 *
 * These transitions contribute to a better user experience by providing a
 * subtle delay for previewing and dismissing the content, reducing visual
 * noise and creating a more polished interaction.
 */
export function PlaceholderPreviewBase({
  name,
  imageUri,
  createdAt,
  isVisible,
  anchorEl,
  datasetName,
  coordinates,
  popperModifiers,
}: PlaceholderPreviewBaseProps): JSX.Element | null {
  const theme = useTheme();

  /**
   * The duration used for both fade in/out of window as well as the warm up and cool down time to make the window visible/invisible
   * i.e handles the delay between pointer hover and the popup visibility both while hovering in (warm-up) and hovering out (cool-down)
   */
  const transitionDuration = theme.transitions.duration.shorter;

  const [isPreviewLoading, setIsPreviewImageLoading] = useState(true);

  const isImageLoading =
    isLoading(imageUri) || (isPreviewLoading && !!imageUri);
  const cardImage = isLoading(imageUri) ? undefined : imageUri ?? undefined;
  const signedCardImage = useValidUrl(cardImage);

  const formattedCoordinates = useMemo(() => {
    if (coordinates) {
      return getCoordinateStringZUp(coordinates);
    }
  }, [coordinates]);

  return (
    <Fade in={isVisible} timeout={transitionDuration}>
      {/** This div is needed to fix an issue with Fade: https://github.com/mui/material-ui/issues/27154#issuecomment-1117386458 */}
      <Box component="div">
        <FaroPopover
          open
          dark
          disablePortal
          placement="bottom"
          anchorEl={anchorEl}
          showCloseButton={false}
          sx={{
            // Remove default padding around content and make it fit the content
            " .MuiPaper-root": {
              // Remove padding on top, between content and arrow
              "> .MuiStack-root": {
                gap: 0,
              },
              p: 0,
              maxWidth: "fit-content",
            },
          }}
          modifiers={popperModifiers}
        >
          <Box
            component="div"
            sx={{
              minWidth: 400,
              background: neutral[950],
              padding: "12px",
              borderRadius: "4px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: "10px" }}
            >
              <FaroText variant="labelM" color={neutral[100]}>
                {name}
              </FaroText>
              <FaroText variant="bodyXS" color={neutral[100]}>
                {createdAt ? convertToDateString(createdAt) : undefined}
              </FaroText>
            </Stack>

            {isImageLoading && (
              <Box component="div" height={PREVIEW_IMAGE_HEIGHT}>
                <Skeleton
                  variant="rectangular"
                  height="100%"
                  sx={{ background: neutral[800] }}
                />
              </Box>
            )}

            <CardMedia
              component="img"
              height={isImageLoading || !imageUri ? 0 : PREVIEW_IMAGE_HEIGHT}
              width="100%"
              onLoad={() => setIsPreviewImageLoading(false)}
              onError={() => setIsPreviewImageLoading(false)}
              image={signedCardImage}
              sx={{
                objectFit: "fill",
              }}
            />
            <PreviewBottomInfo
              datasetName={datasetName}
              coordinates={formattedCoordinates}
            />
          </Box>
        </FaroPopover>
      </Box>
    </Fade>
  );
}

type PreviewBottomInfoProps = Pick<
  PlaceholderPreviewBaseProps,
  "datasetName"
> & {
  /** The formatted coordinates of the pano */
  coordinates?: string;
};

function PreviewBottomInfo({
  datasetName,
  coordinates,
}: PreviewBottomInfoProps): JSX.Element | null {
  if (!datasetName && !coordinates) return null;

  return (
    <Stack flexDirection="row" alignItems="flex-start" sx={{ mt: "10px" }}>
      <Stack direction="row" gap={0.5} alignItems="flex-start" width="50%">
        {datasetName && (
          <PointCloudSmallIcon sx={{ color: neutral[100], fontSize: "14px" }} />
        )}
        <FaroText variant="bodyXS" color={neutral[100]}>
          {datasetName}
        </FaroText>
      </Stack>
      <Stack
        direction="row"
        gap={0.5}
        alignItems="flex-start"
        width="50%"
        justifyContent="flex-end"
      >
        {coordinates && (
          <WorldCoordinateIcon sx={{ color: neutral[100], fontSize: "14px" }} />
        )}
        <FaroText variant="bodyXS" color={neutral[100]}>
          {coordinates}
        </FaroText>
      </Stack>
    </Stack>
  );
}
