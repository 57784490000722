import {
  AlignIcon,
  AlignToModelIcon,
  AlignToPointCloudIcon,
  AlignToSheetIcon,
  AreaIcon,
  CadSmallIcon,
  CameraVideoTrajectorySmallIcon,
  ClockSmallIcon,
  DeleteIcon,
  DisabledIcon,
  DownloadIcon,
  EditIcon,
  FindCheckmarkIcon,
  FolderSmallIcon,
  GeoReferenceIcon,
  Img360SmallIcon,
  InfoIcon,
  LayoutSheetSmallIcon,
  LocationMapIcon,
  MoveElementIcon,
  NonVisibleIcon,
  PointCloudIcon,
  PointCloudImageSmallIcon,
  PointCloudSmallIcon,
  PointCloudTrajectorySmallIcon,
  RegistrationInteractiveIcon,
  ResetScaleIcon,
  SheetNewIcon,
  ToolIcon,
  VideoCameraIcon,
  VisibleIcon,
  WorldCoordinateIcon,
} from "@faro-lotv/flat-ui";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloudIcon from "@mui/icons-material/Cloud";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PanoramaPhotosphereSelectIcon from "@mui/icons-material/PanoramaPhotosphereSelect";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import StraightenIcon from "@mui/icons-material/Straighten";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { SvgIconProps, SvgIconTypeMap, SxProps, Theme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ElementIconType } from "./element-icon-type";

type ElementIconProps = {
  /** Type of icon to render */
  icon: ElementIconType;

  /** Custom style properties */
  sx?: SxProps<Theme>;
};

/** A map to get the icon component based on the desired icon type */
export const TYPE_TO_ICON_MAP: Record<
  ElementIconType,
  | ((props: SvgIconProps) => JSX.Element)
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>
> = {
  [ElementIconType.DisabledIcon]: DisabledIcon,
  [ElementIconType.ClockIcon]: ClockSmallIcon,
  [ElementIconType.MapIcon]: LayoutSheetSmallIcon,
  [ElementIconType.PanoramaIcon]: Img360SmallIcon,
  [ElementIconType.FolderIcon]: FolderSmallIcon,
  [ElementIconType.LocationOnIcon]: LocationOnIcon,
  [ElementIconType.PanoramaPhotosphereSelectIcon]:
    PanoramaPhotosphereSelectIcon,
  [ElementIconType.ImageIcon]: ImageIcon,
  [ElementIconType.AccessTimeFilledIcon]: AccessTimeFilledIcon,
  [ElementIconType.LinkIcon]: LinkIcon,
  [ElementIconType.InfoIcon]: InfoIcon,
  [ElementIconType.VolumeUpIcon]: VolumeUpIcon,
  [ElementIconType.AttachmentIcon]: AttachmentIcon,
  [ElementIconType.SquareFootIcon]: SquareFootIcon,
  [ElementIconType.StraightenIcon]: StraightenIcon,
  [ElementIconType.CloudIcon]: CloudIcon,
  [ElementIconType.PointCloudIcon]: PointCloudIcon,
  [ElementIconType.DeleteIcon]: DeleteIcon,
  [ElementIconType.DownloadIcon]: DownloadIcon,
  [ElementIconType.AlignToPointCloudIcon]: AlignToPointCloudIcon,
  [ElementIconType.RegistrationInteractiveIcon]: RegistrationInteractiveIcon,
  [ElementIconType.AlignToSheetIcon]: AlignToSheetIcon,
  [ElementIconType.ToolIcon]: ToolIcon,
  [ElementIconType.VideoCameraIcon]: VideoCameraIcon,
  [ElementIconType.ResetScaleIcon]: ResetScaleIcon,
  [ElementIconType.DataSession]: PointCloudSmallIcon,
  [ElementIconType.DataSessionVideoRecording]: CameraVideoTrajectorySmallIcon,
  [ElementIconType.DataSessionWithPano]: PointCloudImageSmallIcon,
  [ElementIconType.DataSessionWithTrajectory]: PointCloudTrajectorySmallIcon,
  [ElementIconType.CadModel]: CadSmallIcon,
  [ElementIconType.NonVisible]: NonVisibleIcon,
  [ElementIconType.Visible]: VisibleIcon,
  [ElementIconType.AlignToModelIcon]: AlignToModelIcon,
  [ElementIconType.GeoReferenceIcon]: GeoReferenceIcon,
  [ElementIconType.FindCheckmarkIcon]: FindCheckmarkIcon,
  [ElementIconType.WorldCoordinateIcon]: WorldCoordinateIcon,
  [ElementIconType.EditIcon]: EditIcon,
  [ElementIconType.MoveElement]: MoveElementIcon,
  [ElementIconType.AlignIcon]: AlignIcon,
  [ElementIconType.SheetNewIcon]: SheetNewIcon,
  [ElementIconType.AreaIcon]: AreaIcon,
  [ElementIconType.OverviewMapIcon]: LocationMapIcon,
};

/**
 * @returns the proper Icon component for a ElementIconType
 */
export function ElementIcon({ icon, sx }: ElementIconProps): JSX.Element {
  const Icon = TYPE_TO_ICON_MAP[icon];
  return <Icon sx={sx} />;
}
