import { useCameraInCurrentScene } from "@/modes/alignment-modes-commons/align-to-cad-utils";
import { PointCloudObject } from "@/object-cache";
import { EntityPinControls } from "@/registration-tools/common/interaction/entity-pin-controls";
import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import {
  centerCameraOnPointClouds,
  computeCombinedPointCloudBoundingBox,
  computeCombinedPointCloudCenter,
} from "@/registration-tools/utils/camera-views";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  ExplorationControls,
  useNonExhaustiveEffect,
  useReproportionCamera,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { EMPTY_ARRAY, GUID, TypedEvent } from "@faro-lotv/foundation";
import {
  Map2DControls as Map2DControlsImpl,
  WalkOrbitControls,
} from "@faro-lotv/lotv";
import { DataSetLocalPose } from "@faro-lotv/service-wires";
import { Camera } from "@react-three/fiber";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box3,
  Matrix4,
  OrthographicCamera,
  PerspectiveCamera,
  Vector3,
} from "three";
import { useCameraAnimation } from "../hooks/use-camera-animation";
import { selectSelectedEntityIds } from "../store/data-preparation-ui/data-preparation-ui-selectors";
import {
  selectRevisionEntity,
  selectRevisionEntityWorldTransformCache,
} from "../store/revision-selectors";
import { computeLocalEntityPoseFromWorldTransforms } from "../store/revision-transform-cache";
import { CameraOptions3D } from "../ui/camera-switch";
import { Projection } from "../ui/projection-switch";
import {
  calculateCameraDistanceToFrameSize,
  frameOrthoCameraFromPerspectiveView,
  framePerspectiveCameraFromOrthoView,
} from "../utils/ortho-perspective-switch-utils";

/** Position threshold at which an animation is played */
const ANIMATION_THRESHOLD = 0.1;

type RevisionScansControlsProps = {
  /** An event which is triggered when the camera should be centered. */
  centerCameraEvent: TypedEvent<Perspective>;

  /** The point cloud objects in the scene. */
  pointCloudObjects: PointCloudObject[];

  /** The current projection */
  projection: Projection;

  /** The current camera in 3D projection */
  cameraOptions3D: CameraOptions3D;

  /** Whether the user can move and rotate scans. */
  isEditingScans: boolean;

  /** Callback when the user adds a new transform override for a point cloud. */
  onManualOverrideAdded(id: GUID, pose: DataSetLocalPose): void;
};

/**
 * @returns Controls to inspect the scans and manage the camera.
 */
export function RevisionScansControls({
  projection,
  cameraOptions3D,
  pointCloudObjects,
  centerCameraEvent,
  isEditingScans,
  onManualOverrideAdded,
}: RevisionScansControlsProps): JSX.Element {
  const [activeProjection, setActiveProjection] = useState(
    Projection.twoDimensional,
  );

  const { getState } = useAppStore();

  const [orthoCamera2D] = useState(() => new OrthographicCamera());
  const [orthoCamera3D] = useState(() => new OrthographicCamera());
  const [perspCamera] = useState(() => new PerspectiveCamera());

  const [active3DCamera, setActive3DCamera] = useState<CameraOptions3D>(
    CameraOptions3D.perspective,
  );

  const explorationControlsRef = useRef<WalkOrbitControls>(null);
  const map2dControlsRef = useRef<Map2DControlsImpl>(null);

  const [lastTarget, setLastTarget] = useState(() =>
    computeCombinedPointCloudCenter(pointCloudObjects),
  );

  const { animationToRender, startAnimation, animationCamera } =
    useCameraAnimation();

  let activeCamera: Camera = orthoCamera2D;

  if (animationToRender) {
    activeCamera = animationCamera;
  } else if (activeProjection === Projection.twoDimensional) {
    activeCamera = orthoCamera2D;
  } else if (active3DCamera === CameraOptions3D.orthographic) {
    activeCamera = orthoCamera3D;
  } else {
    activeCamera = perspCamera;
  }

  useCameraInCurrentScene(activeCamera);
  useReproportionCamera(activeCamera);

  // Should not re-render for every change in camera position or rotation
  useNonExhaustiveEffect(() => {
    // Transition of projection
    if (projection !== activeProjection) {
      // Change from 2D to 3D
      if (projection === Projection.threeDimensional) {
        // Set 3D orthographic camera with respect to 2D Orthographic camera
        orthoCamera3D.position.copy(orthoCamera2D.position);
        orthoCamera3D.quaternion.copy(orthoCamera2D.quaternion);

        // Set 3D perspective camera with respect to 2D Orthographic camera
        setLastTarget(
          framePerspectiveCameraFromOrthoView(
            perspCamera,
            orthoCamera2D,
            lastTarget,
          ),
        );
      }
      // 3D Perspective to 2D
      else if (active3DCamera === CameraOptions3D.perspective) {
        const referencePoint = explorationControlsRef.current?.target;

        // Skip logic if controls have been in walk mode. Use the last camera state as a fallback.
        if (referencePoint) {
          frameOrthoCameraFromPerspectiveView(
            orthoCamera2D,
            perspCamera,
            referencePoint,
          );

          // The animation "rotates" the perspective camera around the pivot to match the orthographic camera's direction
          startAnimation(perspCamera, {
            position: referencePoint
              .clone()
              .add(
                new Vector3(
                  0,
                  0,
                  perspCamera.position.distanceTo(referencePoint),
                ).applyQuaternion(orthoCamera2D.quaternion),
              ),
            quaternion: orthoCamera2D.quaternion,
            duration: 0.5,
          });

          setLastTarget(referencePoint);
        }
      }
      setActiveProjection(projection);
    }
  }, [
    projection,
    activeProjection,
    orthoCamera2D,
    active3DCamera,
    perspCamera,
    lastTarget,
    startAnimation,
  ]);

  useEffect(() => {
    // Transition of 3D camera
    if (cameraOptions3D !== active3DCamera) {
      // 3D Orthographic to 3D Perspective
      if (cameraOptions3D === CameraOptions3D.perspective) {
        setLastTarget(
          framePerspectiveCameraFromOrthoView(
            perspCamera,
            orthoCamera3D,
            lastTarget,
          ),
        );
      }
      // 3D Perspective to 3D Orthographic
      else {
        const referencePoint = explorationControlsRef.current?.target;
        if (referencePoint) {
          frameOrthoCameraFromPerspectiveView(
            orthoCamera3D,
            perspCamera,
            referencePoint,
          );
          setLastTarget(referencePoint);
        }

        orthoCamera3D.position.copy(perspCamera.position);
        orthoCamera3D.quaternion.copy(perspCamera.quaternion);
      }
      setActive3DCamera(cameraOptions3D);
    }
  }, [cameraOptions3D, active3DCamera, perspCamera, orthoCamera3D, lastTarget]);

  const centerCamera = useCallback(
    (perspective: Perspective) => {
      if (activeProjection === Projection.twoDimensional) {
        centerCameraOnPointClouds(
          pointCloudObjects,
          orthoCamera2D,
          perspective,
        );

        if (map2dControlsRef.current) {
          // Whenever the view type (perspective) changes,
          // the camera is re-assigned to the controls so its
          // pose is not changed by the controls.
          map2dControlsRef.current.camera = orthoCamera2D;
        }
      } else if (active3DCamera === CameraOptions3D.orthographic) {
        centerCameraOnPointClouds(pointCloudObjects, orthoCamera3D);
      } else if (explorationControlsRef.current) {
        const bbox = computeCombinedPointCloudBoundingBox(
          pointCloudObjects,
          new Box3(),
        );

        const target = bbox.getCenter(new Vector3());
        const cameraPosition = target
          .clone()
          .add(
            new Vector3(0, 0, 1)
              .applyQuaternion(perspCamera.quaternion)
              .multiplyScalar(
                calculateCameraDistanceToFrameSize(
                  perspCamera.getEffectiveFOV(),
                  bbox.max.distanceTo(bbox.min),
                ),
              ),
          );

        if (
          perspCamera.position.distanceTo(cameraPosition) > ANIMATION_THRESHOLD
        ) {
          startAnimation(perspCamera, {
            position: cameraPosition,
          });
        }

        perspCamera.position.copy(cameraPosition);
        explorationControlsRef.current.target = target;
      }

      setLastTarget(computeCombinedPointCloudCenter(pointCloudObjects));
    },
    [
      active3DCamera,
      activeProjection,
      pointCloudObjects,
      orthoCamera3D,
      orthoCamera2D,
      perspCamera,
      startAnimation,
    ],
  );
  useTypedEvent<Perspective>(centerCameraEvent, centerCamera);

  const selectedEntityIds = useAppSelector(selectSelectedEntityIds);

  const onSelectedEntitiesMoved = useCallback(
    (transformChange: Matrix4) => {
      const tempEntityMatrix = new Matrix4();
      const tempParentMatrix = new Matrix4();

      for (const id of selectedEntityIds) {
        const entity = selectRevisionEntity(id)(getState());
        if (!entity) continue;

        // Apply the change to the entity
        const entityTransform = tempEntityMatrix
          .fromArray(
            selectRevisionEntityWorldTransformCache(id)(getState()).worldMatrix,
          )
          .premultiply(transformChange);

        const parentTransform =
          // A root element needs to be indicated by undefined to computeLocalEntityPoseFromWorldTransforms, not a default transform
          entity.parentId === null
            ? undefined
            : tempParentMatrix.fromArray(
                selectRevisionEntityWorldTransformCache(entity.parentId)(
                  getState(),
                ).worldMatrix,
              );

        onManualOverrideAdded(
          id,
          // Convert back to local transform and capture tree coordinate system
          computeLocalEntityPoseFromWorldTransforms(
            entityTransform,
            parentTransform,
          ),
        );
      }
    },
    [selectedEntityIds, getState, onManualOverrideAdded],
  );

  if (animationToRender) {
    return animationToRender;
  }

  switch (activeProjection) {
    case Projection.twoDimensional:
      return (
        <EntityPinControls
          manipulatedEntityIds={
            isEditingScans ? selectedEntityIds : EMPTY_ARRAY
          }
          onTransform={onSelectedEntitiesMoved}
          mapControlsRef={map2dControlsRef}
          camera={orthoCamera2D}
        />
      );
    case Projection.threeDimensional:
      return (
        <ExplorationControls
          ref={explorationControlsRef}
          camera={
            active3DCamera === CameraOptions3D.orthographic
              ? orthoCamera3D
              : perspCamera
          }
          target={lastTarget}
        />
      );
  }
}
