import {
  blue,
  CoordinatesIcon,
  FaroText,
  neutral,
} from "@faro-lotv/app-component-toolbox";
import { Box, Stack } from "@mui/material";

type ControlPointsAlignmentDescriptionProps = {
  /** width of description table */
  width: string;
};
/** @returns render description of control-points alignment */
export function ControlPointsAlignmentDescription({
  width,
}: ControlPointsAlignmentDescriptionProps): JSX.Element {
  // Shared styles
  const textStyles = {
    fontFamily: "Open Sans",
    fontSize: 14,
    lineHeight: "20px",
    p: 3,
  };

  return (
    <Box
      component="div"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Box
        component="div"
        border={2}
        borderColor={neutral[300]}
        mr={5}
        width={width}
        height="40%"
      >
        <Stack direction="row" width="100%" height="100%">
          <Box
            component="div"
            width="30%"
            height="100%"
            display="flex"
            alignContent="center"
            justifyContent="center"
          >
            <Stack
              direction="column"
              justifyContent="center"
              width="100%"
              height="100%"
              sx={{
                backgroundColor: neutral[50],
              }}
            >
              <Box component="div" display="flex" justifyContent="center">
                <CoordinatesIcon
                  sx={{
                    width: "80px",
                    height: "80px",
                    color: neutral[600],
                  }}
                />
              </Box>
              <FaroText
                variant="labelM"
                fontWeight={400}
                align="center"
                color={blue[400]}
                {...textStyles}
              >
                Align your sheet with only 2 clicks!
              </FaroText>
            </Stack>
          </Box>

          <Box component="div" width="70%" height="100%">
            <Stack
              direction="column"
              width="100%"
              height="100%"
              justifyContent="center"
            >
              <FaroText
                variant="heading16"
                fontWeight={600}
                fontSize={16}
                lineHeight="24px"
                sx={{ pb: 1, pl: 3 }}
              >
                Control points alignment
              </FaroText>
              <FaroText
                variant="bodyM"
                fontWeight={400}
                {...textStyles}
                sx={{ pt: 1 }}
              >
                Use precise coordinates to align your sheet. Select two points
                and insert their X Y Z references.
              </FaroText>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
