import {
  FaroTooltip,
  OrthographicCameraIcon,
  PerspectiveCameraIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { MouseEvent, useCallback } from "react";

export enum CameraOptions3D {
  /** orthographic setting for camera */
  orthographic = "orthographic",

  /** perspective setting for camera */
  perspective = "perspective",
}

type CameraSwitchProps = {
  /** The currently active camera in 3D projection. */
  cameraOptions3D: CameraOptions3D;

  /** A callback to execute when the user changes the camera in 3D. */
  onCameraChange(camera: CameraOptions3D): void;
};

/** @returns A toolbar to change the camera in 3D projection */
export function CameraSwitch({
  cameraOptions3D,
  onCameraChange,
}: CameraSwitchProps): JSX.Element {
  const changeCamera = useCallback(
    (_event: MouseEvent, value: CameraOptions3D | null) => {
      if (value) {
        onCameraChange(value);
      }
    },
    [onCameraChange],
  );

  return (
    <Toolbar>
      <ToolGroup
        value={cameraOptions3D}
        orientation="vertical"
        exclusive
        onChange={changeCamera}
      >
        <FaroTooltip title="Perspective Camera" placement="right">
          <ToolButton
            value={CameraOptions3D.perspective}
            selected={cameraOptions3D === CameraOptions3D.perspective}
          >
            <PerspectiveCameraIcon />
          </ToolButton>
        </FaroTooltip>
        <FaroTooltip title="Orthographic Camera" placement="right">
          <ToolButton
            value={CameraOptions3D.orthographic}
            selected={cameraOptions3D === CameraOptions3D.orthographic}
          >
            <OrthographicCameraIcon />
          </ToolButton>
        </FaroTooltip>
      </ToolGroup>
    </Toolbar>
  );
}
